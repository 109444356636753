import { render, staticRenderFns } from "./investor-management.vue?vue&type=template&id=967a268e&scoped=true&"
import script from "./investor-management.vue?vue&type=script&lang=js&"
export * from "./investor-management.vue?vue&type=script&lang=js&"
import style0 from "./investor-management.vue?vue&type=style&index=0&id=967a268e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "967a268e",
  null
  
)

export default component.exports